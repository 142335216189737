<template>
  <div class="intro-language">
    <div class="ct-logo" v-if="!user"></div>
    <h4>An IDEO Production</h4>
    <h1>
      Conversations that move.
    </h1>
  </div>
</template>
<style lang="scss">
@import "../../styles/variables";
.intro-language {
  .ct-logo {
    margin-bottom: 50px;
    height: 100px;
    width: 200px;
  }
  h1 {
    padding-left: 60px;
    font-size: 4rem;
    @media only screen and (max-width: 812px) {
      font-size: 2.5rem;
      padding-left: 0;
    }
    text-align: left;
    text-transform: none;
    font-family: $san-serif;
    font-weight: 200;
    margin-top: 0;
    margin-bottom: 3rem;
  }
  h4 {
    padding-left: 60px;
    font-weight: 400;
    letter-spacing: 2px;
    text-align: left;
    @media only screen and (max-width: 812px) {
      padding-left: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["user"])
  }
};
</script>
