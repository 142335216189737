<template>
  <div class="file-upload">
    <label for="file-upload">
      <input
        type="file"
        ref="file"
        id="file-upload"
        accept="image/*"
        @change="updateImage"
      />
      <slot />
    </label>
  </div>
</template>
<style lang="scss">
@import "../styles/variables";

.file-upload {
  position: relative;
  color: $copy;

  input[type="file"] {
    display: none;
  }

  label {
    display: inline-flex;
    flex-direction: column;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
<script>
export default {
  props: ["validate"],
  data() {
    return {
      previewImage: null
    };
  },
  methods: {
    open() {
      this.$refs.file.click();
    },
    updateImage(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        const imageFile = input.files[0];
        if (this.validate instanceof Function) {
          try {
            this.validate(imageFile);
          } catch (err) {
            input.value = null;
            throw err;
          }
        }
        if (this.previewImage) {
          URL.revokeObjectURL(this.previewImage);
        }
        
        this.resizeImage(imageFile, 256, 256, (resizedBlob, previewUrl) => {
          this.previewImage = previewUrl;
          this.$emit("change", {
            file: resizedBlob,
            preview: this.previewImage
          });
        });
      }
    },
    resizeImage(file, maxWidth, maxHeight, callback) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // Create a canvas element
          const canvas = document.createElement('canvas');
          canvas.width = maxWidth;
          canvas.height = maxHeight;
          const ctx = canvas.getContext('2d');
          
          // Clear canvas and draw resized image with proper aspect ratio
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          
          // Calculate dimensions to maintain aspect ratio
          const aspectRatio = img.width / img.height;
          let drawWidth = maxWidth;
          let drawHeight = maxHeight;
          let offsetX = 0;
          let offsetY = 0;
          
          // Center crop to square
          if (aspectRatio > 1) {
            // Width is greater than height (landscape)
            drawWidth = img.width * (maxHeight / img.height);
            offsetX = (maxWidth - drawWidth) / 2;
          } else if (aspectRatio < 1) {
            // Height is greater than width (portrait)
            drawHeight = img.height * (maxWidth / img.width);
            offsetY = (maxHeight - drawHeight) / 2;
          }
          
          ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);
          
          // Convert canvas to blob
          canvas.toBlob((blob) => {
            const resizedUrl = URL.createObjectURL(blob);
            callback(blob, resizedUrl);
          }, file.type);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }
};
</script>
