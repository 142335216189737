<template>
  <div class="profile-container">
    <div class="wrapper">
      <div class="row top-xs start-md center-xs" v-if="useAvatar">
        <div class="col-md-6 col-xs-12">
          <h4 class="step1">1. Customize Avatar</h4>
          <p class="instruction">
            Choose your avatar color
          </p>
          <button class="secondary" @click="useAvatar = false">
            Switch to photo
          </button>
        </div>
        <div class="col-md-6 col-xs-12 center-xs">
          <div class="file-upload">
            <div class="avatar-preview">
              <img referrerpolicy="no-referrer" :src="form.avatar" />
            </div>
          </div>
        </div>
        <div class="col-xs-12">
          <avatar-configurer
            :value="form.avatar"
            @change="updateForm('avatar', $event)"
          />
        </div>
      </div>
      <div class="row top-xs start-md center-xs" v-else>
        <div class="col-md-6 col-xs-12">
          <h4 class="step1">1. Upload Photo</h4>
          <p class="instruction">
            Your photo will be automatically resized to 256x256 pixels
          </p>
          <button class="secondary" @click="useAvatar = true">
            Switch to avatar
          </button>
        </div>
        <div class="col-md-6 col-xs-12 center-xs">
          <image-upload
            @change="updateImage($event)"
            ref="imageUpload"
            :validate="validateProfileImage"
          >
            <div v-if="form.picture" class="avatar-preview">
              <img referrerpolicy="no-referrer" :src="form.picture" />
            </div>
          </image-upload>
          <div class="actions">
            <div v-if="user && user.photos.length">
              <img
                class="photo"
                referrerpolicy="no-referrer"
                v-for="photo in user.photos"
                :key="photo"
                :src="photo"
                @click="form.picture = photo"
              />
            </div>
            <span v-if="user && user.photos.length" class="instruction">
              - or -
            </span>
            <button class="secondary" @click="$refs.imageUpload.open()">
              Upload Photo
            </button>
          </div>
        </div>
      </div>
      <div class="row middle-xs start-md center-xs">
        <div class="col-md-6 col-xs-12">
          <h4 class="step2">2. Display Name</h4>
          <p class="instruction">
            This is how you will appear to others
          </p>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="form-input">
            <div class="input">
              <input
                type="text"
                placeholder="Enter your name"
                :value="form.name"
                @input="updateForm('name', $event.target.value)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row top-xs start-md center-xs">
        <div class="col-md-6 col-xs-12">
          <h4>3. Application Analytics</h4>
          <p class="instruction">
            Help improve this app by sending basic usage data to the Creative
            Tensions App team.
          </p>
        </div>
        <div class="col-md-6 col-xs-12 center-xs">
          <label class="switch">
            <input
              type="checkbox"
              :checked="userPrefs.analyticsEnabled"
              @change="enableAnalytics($event.target.checked)"
            />
            <span class="toggle"></span>
          </label>
        </div>
        <div class="col-xs-12 center-xs notification-container">
          <label class="notification" v-show="isLoading">
            <app-spinner /> Saving...
          </label>
          <label class="notification" v-show="!isLoading && !isModified">
            ✔ Changes saved
          </label>
        </div>
        <div class="col-xs-12 center-xs">
          <button :disabled="!saveButtonEnabled" @click="handleSaveClicked()">
            {{ saveButtonLabel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "../styles/variables";
@import "../styles/mixins";

.profile-container {
  @include full-height;
  .wrapper {
    padding-bottom: 2em;
    padding-top: 2em;
    max-width: 700px;
  }

  .row {
    border-bottom: 1px solid $line-color;
    padding: 3rem 0;

    &:last-child {
      border-bottom: none;
    }
  }

  button.secondary {
    border-radius: 40px;
  }

  .file-upload {
    margin: 30px 0;
  }

  .avatar-preview {
    display: inline-block;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 4px solid $primary;

    img {
      display: block;
      object-fit: cover;
      overflow: hidden;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      transform: scale(0.95);
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    .instruction {
      display: inline-block;
      margin: 0 10px;
    }
    .photo {
      display: inline-block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      vertical-align: middle;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .instruction {
    margin-bottom: 2rem;
  }

  .form-input {
    input {
      text-align: center;
    }
  }

  .switch {
    margin: 1rem 0 3rem;
  }

  .notification-container {
    min-height: 34px;
    .notification {
      padding: 10px 0;
      justify-content: center;
    }
  }
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";
import { uploadProfileImage } from "../api/user";
import ImageUpload from "../components/ImageUpload";
import AvatarConfigurer from "../components/AvatarConfigurer";
import AppSpinner from "../components/shared/AppSpinner";
import { signInAnonymously, unlink } from "../api/auth";
import { ROUTES } from "../router";

export const MAX_PROFILE_SIZE = 1 * 1024 * 1024;

export default {
  components: {
    AppSpinner,
    ImageUpload,
    AvatarConfigurer
  },
  data() {
    return {
      form: {
        name: null,
        avatar: null,
        picture: null,
        imageFile: null
      },
      isLoading: false,
      useAvatar: false
    };
  },
  computed: {
    ...mapGetters(["user", "userPrefs"]),
    isValid() {
      const errors = this.validateProfile(this.form);
      return errors.length === 0;
    },
    isModified() {
      const user = this.user || {};
      const { name, picture, avatar } = this.form;
      return (
        name !== user.name ||
        (this.useAvatar && avatar !== user.picture) ||
        (!this.useAvatar && picture !== user.picture)
      );
    },
    saveButtonEnabled() {
      if (this.isLoading) {
        return false;
      }

      if (this.$route.query.redirect) {
        return this.isValid;
      } else {
        return this.isValid && this.isModified;
      }
    },
    saveButtonLabel() {
      if (this.$route.query.redirect) {
        return "Save and Continue";
      } else {
        return "Update Profile";
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    // Add redirect back to current event
    if (from.name === ROUTES.EVENT_PARTICIPATE && !to.query.redirect) {
      to.query.redirect = from.fullPath;
      return next(to);
    }
    next();
  },
  mounted() {
    if (!this.user) return;

    this.useAvatar =
      this.user.picture && this.user.picture.startsWith("data:image/svg+xml");

    this.form.name = this.user.name;
    if (this.useAvatar) {
      this.form.avatar = this.user.picture;
    } else {
      this.form.picture = this.user.picture;
    }
  },
  beforeDestroy() {
    if (this.form.picture) {
      URL.revokeObjectURL(this.form.picture);
    }
  },
  methods: {
    ...mapActions(["updateUser", "enableAnalytics"]),
    // eslint-disable-next-line no-unused-vars
    validateProfileImage(imageFile) {
      // No size validation required as we resize the image
      return true;
    },
    validateProfile(form) {
      let errors = [];
      if (!form.name) {
        errors.push("Please add a display name");
      }
      if (!form.picture && !form.imageFile && !this.useAvatar) {
        errors.push("Please upload a profile image");
      }
      return errors;
    },
    updateImage({ file, preview }) {
      this.form.imageFile = file;
      this.form.picture = preview;
    },
    updateForm(field, value) {
      this.form[field] = value;
    },
    unlinkProvider(providerId) {
      unlink(providerId);
    },
    async updateUserProfile() {
      const { picture, avatar, imageFile, name } = this.form;
      const errors = this.validateProfile(this.form);
      if (errors.length) {
        throw new Error(errors.join("\n"));
      } else {
        if (!this.user) {
          await signInAnonymously();
        }
        const userUpdates = {};
        if (!this.useAvatar && imageFile) {
          userUpdates.picture = await uploadProfileImage(imageFile);
        } else if (this.useAvatar) {
          userUpdates.picture = avatar;
        } else if (picture !== this.user.picture) {
          userUpdates.picture = picture;
        }
        if (name) {
          userUpdates.name = name;
        }
        await this.updateUser(userUpdates);
      }
    },
    async handleSaveClicked() {
      let promise = Promise.resolve();
      this.isLoading = true;

      if (this.isModified) {
        try {
          await this.updateUserProfile();
        } catch (err) {
          this.isLoading = false;
          throw err;
        }
      }

      const { redirect } = this.$route.query;
      if (redirect) {
        promise = this.$router.push(redirect);
      }

      promise.then(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>
